import { useEffect } from "react";

export const useChangeAttributesForIframes = () => {
  const bodyTag = document.getElementsByTagName("body")[0];
  // Options for the observer (which mutations to observe)
  const config = { childList: true, subtree: true };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(bodyTag, config);

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  });
};

// Callback function to execute when mutations are observed
const callback = function(mutationsList: any, observer: any) {
  // Use traditional 'for loops' for IE 11
  for (const mutation of mutationsList) {
    if (mutation.type === "childList" || mutation.type === "subtree") {
      const iframes = document?.getElementsByTagName("iframe");

      for (let i = 0; i < iframes.length; i++) {
        if (iframes[i].src.includes("recaptcha")) {
          iframes[i].setAttribute(
            "style",
            "width: 306px; height: 80px; border: solid 1px #fff"
          );
        }
        iframes[i].removeAttribute("frameborder");
        iframes[i].removeAttribute("scrolling");
      }
    }
  }
};
