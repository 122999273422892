import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { ListItem } from "./ListItem";
import { Redirection } from "./Redirection";

const MenuItem = ({ item }) => {
  const { isBifTheme } = useMappedState(mapStateToProps);
  return (
    <>
      {isBifTheme ? (
        <BifListItem>
          <Redirection item={item} />
        </BifListItem>
      ) : (
        <ListItem>
          <Redirection item={item} />
        </ListItem>
      )}
    </>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  isBifTheme: PropTypes.bool
};

const mapStateToProps = ({ context }) => ({
  isBifTheme: context.siteTheme === "bif"
});

const BifListItem = styled(ListItem)`
  margin-left: -100%;
  padding-left: 100%;
  border-bottom: none;
  a {
    border-bottom: none;
    border-left: 6px solid transparent;
    &.active {
      border-left-color: ${({ theme }) => theme.secondaryColor};
      border-right-color: transparent;
    }
  }
`;

export { MenuItem };
