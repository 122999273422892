import React from "react";
import Helmet from "react-helmet";
import { Route, Switch } from "react-router";
import { useMappedState } from "redux-react-hook";
import { ThemeProvider } from "styled-components";
import ReactGA from "react-ga4";
import useRouter from "use-react-router";
// @ts-ignore
import { NotificationContainer } from "react-notifications";

import themes from "../themes/index";

import { ErrorPanel } from "components/ErrorPanel";
import Layout from "components/layout/index";
import Routes from "components/routes";
import { Loader } from "components/loader/index";
import { PrivacyPolicy } from "components/privacy-policy/index";
import { previewRoutes } from "components/routes";
import { useGetDeclarationOfAvailabilityUrl } from "../utils/useGetDeclarationOfAvailabilityUrl";
import {useChangeAttributesForIframes} from "../utils";

const AppContent = () => {
  const {
    isContrast,
    accessibilityPageEnabled,
    siteTitle,
    documentTitle,
    googleAnalyticsTrackingId,
    langCode,
    siteTheme
  } = useMappedState(mapStateToProps);
  const { location } = useRouter();
  useChangeAttributesForIframes();

  const declarationOfAvailabilityUrl = useGetDeclarationOfAvailabilityUrl();

  const theme = isContrast
    ? themes.contrast
    : // @ts-ignore
      themes[siteTheme] || themes.primary;
  const title = siteTitle
    ? `${documentTitle !== null ? `${documentTitle} | ` : ""}${siteTitle}`
    : "Biuletyn Informacji Publicznej";

  const lang = langCode ? langCode.split("_")[0] : "pl";

  if (googleAnalyticsTrackingId) {
    ReactGA.initialize(googleAnalyticsTrackingId, {
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    });
    ReactGA.send({ hitType: "pageview" });
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet defer={false}>
          <meta
            data-showdeclaration
            name="deklaracja-dostępności"
            content={
              accessibilityPageEnabled ? declarationOfAvailabilityUrl : ""
            }
          />
          <meta name="description" content={title} />
          <title>{title}</title>
          <html lang={lang} />
        </Helmet>
        <Switch>
          {previewRoutes.map(previewRoute => {
            return (
              <Route
                key={previewRoute.path}
                path={previewRoute.path}
                component={previewRoute.component}
              />
            );
          })}
          <Route
            path="/(.*)?/not-found"
            component={() => (
              <ErrorPanel header="404" content="Nie odnaleziono strony" />
            )}
          />
          <Layout>
            <Routes />
          </Layout>
        </Switch>
        <Loader />
        <NotificationContainer />
        <PrivacyPolicy />
      </>
    </ThemeProvider>
  );
};

const mapStateToProps = ({
  context,
  accessibility,
  documentTitle,
  language
}: any) => ({
  siteTitle: context.siteTitle,
  googleAnalyticsTrackingId: context.googleAnalyticsTrackingId,
  isContrast: accessibility.isContrast,
  documentTitle,
  langCode: language.langCode,
  siteTheme: context.siteTheme,
  accessibilityPageEnabled: context.accessibilityPageEnabled
});

export { AppContent };
