import React, { useEffect } from "react";
import ReactGA from "react-ga";
import useRouter from "use-react-router";
import { useDispatch } from "redux-react-hook";
import { useQueryClient } from "react-query";

import "leaflet-defaulticon-compatibility";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-notifications/lib/notifications.css";

import "../styles/index.css";

import { fetchContextSucceeded } from "redux/modules/context";
import {
  fetchAdditionalMenuSucceeded,
  fetchMenuSucceeded
} from "redux/modules/menu";
import { shouldCreateAdditionalMenuItem } from "redux/utils/shouldCreateAdditionalMenuItem";
import { setLanguage, setTranslations } from "redux/modules/language";
import { setContrast } from "redux/modules/accessibility";
import { createSearchUrl } from "redux/utils/createSearchUrl";
import { prepareUrl } from "redux/utils/prepareUrl";

import { getAllByUrl } from "../api/urls";
import { getTranslation } from "../api/language";

import { isContrast } from "utils/accessibility";
import { getEnabledMenuElements } from "../utils/menu";

import { RemoteDataSuspense } from "components/RemoteData/RemoteDataSuspense";
import { RemoteData } from "components/RemoteData/RemoteData";

import { AppContent } from "./AppContent";
import { AppLoader } from "./AppLoader";

import { IStartData } from "../types/IStartData";
import { applicationKeys } from "./keys";
import PageNotFound from "../containers/PageNotFound";
import { ILanguageTranslation } from "../types/ILanguage";
import { useFontSize } from "../utils/useFontSize";

const App = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { location } = useRouter();
  const { initFontSizeInDocument } = useFontSize();

  useEffect(() => {
    //potrzebne kiedy klikamy wstecz i wracamy do strony głównek
    //ustawione dlatego, że ma się odświeżyć cache tylko w tym przypadku
    //inaczej get-all mógłby być wywoływany z każdą zmianą urla, ale to zbyt kosztowne
    if (window.location.pathname === "/") {
      queryClient.invalidateQueries([applicationKeys.application]);
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <RemoteDataSuspense<IStartData>
      queryKey={[applicationKeys.application]}
      fetcher={() => getAllByUrl(prepareUrl(window.location.pathname))}
      errorFallback={() => <PageNotFound />}
      fallback={<AppLoader />}
      configuration={{
        refetchOnWindowFocus: false,
        suspense: true,
        retry: false
      }}
    >
      {({ context, menu, resource }) => {
        dispatch(setLanguage(resource.lang));
        dispatch(setContrast(context.showBlindControl ? isContrast() : false));
        initFontSizeInDocument();
        ReactGA.pageview(location.pathname + location.search);

        const contextFriendlyUrl = resource.context || "default";
        const searchUrl = createSearchUrl(
          context.urlType,
          resource.lang,
          contextFriendlyUrl
        );
        const enhancedContext = {
          ...context,
          contextFriendlyUrl,
          searchUrl
        };
        dispatch(fetchContextSucceeded(enhancedContext));
        dispatch(fetchMenuSucceeded(menu || []));
        if (shouldCreateAdditionalMenuItem(context)) {
          const additionalMenuElements = getEnabledMenuElements(
            resource.lang,
            enhancedContext
          );
          dispatch(fetchAdditionalMenuSucceeded(additionalMenuElements));
        }

        return (
          <RemoteData<ILanguageTranslation[]>
            errorFallback={() => <AppContent />}
            fetcher={() => getTranslation(resource.lang)}
            queryKey={[applicationKeys.translations]}
            configuration={{
              refetchOnWindowFocus: false,
              suspense: true,
              retry: false
            }}
          >
            {data => {
              dispatch(setTranslations(data));
              return <AppContent />;
            }}
          </RemoteData>
        );
      }}
    </RemoteDataSuspense>
  );
};

export default App;
