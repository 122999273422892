import React from "react";
import useMedia from "use-media";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { getRssUrl } from "api/contexts";

import { ExternalLinkButton } from "../../../buttons";
import { MobileAccessibilityMenu } from "./components/MobileAccessibilityMenu";
import { AccessibilityMenu } from "./components/AccessibilityMenu";
import Authentication from "../authentication/Authentication";
import { SearchBar } from "./components/search-bar/SearchBar";
import { AccessibilityLanguageDropdown } from "./components/Buttons/AccessibilityLanguageDropdown";
import { useIncreaseDecreaseFontSize } from "./components/useIncreaseDecreaseFontSize";

const Tools = () => {
  const isMobile = useMedia({ maxWidth: "767px" });
  useIncreaseDecreaseFontSize();

  const {
    friendlyUrl,
    deafArticleUrl,
    helpArticleUrl,
    authenticationEnabled
  } = useMappedState(mapStateToProps);
  const rssUrl = getRssUrl(friendlyUrl);

  return (
    <Container>
      <AccessibilityLanguageDropdown />
      <StyledLink
        href={rssUrl}
        id="rss"
        target="rss_resource"
        title="Rss"
        aria-label="Rss"
        data-color="secondary"
      >
        <span aria-hidden={true} className="fa fa-rss" />
      </StyledLink>
      {helpArticleUrl && (
        <StyledLink
          //@todo: Obejście prawdziwego problemu, gdzie nie do końca działa odświeżenie breadcrumbsów i aktualności w menu po przejściu do danego artykułu
          // Prawdopodobnie trzeba odświeżać endpoint get-all, ale najlepiej zastanowić sie nad nową architekturą całego bipa i systemem cache'owania
          href={`${window.location.protocol}//${window.location.hostname}/${helpArticleUrl}`}
          target="_self"
          title="Pomoc"
          aria-label="Pomoc"
          data-color="secondary"
          id="help-article"
        >
          <span aria-hidden={true} className="fa fa-question-circle" />
        </StyledLink>
      )}
      {deafArticleUrl && (
        <StyledLink
          href={`${window.location.protocol}//${window.location.hostname}/${deafArticleUrl}`}
          target="_self"
          title="Informacja dla osób niesłyszących"
          aria-label="Informacja dla osób niesłyszących"
          data-color="secondary"
          id="deaf-article"
        >
          <span aria-hidden={true} className="fa fa-deaf" />
        </StyledLink>
      )}
      {isMobile && (
        <StyledMobileAccessibilityMenu>
          <MobileAccessibilityMenu />
        </StyledMobileAccessibilityMenu>
      )}
      <AccessibilityAuthenticationContainer>
        {!isMobile && <AccessibilityMenu />}
        {authenticationEnabled && <Authentication />}
      </AccessibilityAuthenticationContainer>
      <SearchBar/>
    </Container>
  );
};

const mapStateToProps = ({ context }: any) => ({
  friendlyUrl: context.contextFriendlyUrl,
  helpArticleUrl: context.helpArticleUrl,
  deafArticleUrl: context.deafArticleUrl,
  authenticationEnabled: context.userLogInEnabled
});

const StyledLink = styled(ExternalLinkButton)`
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const StyledMobileAccessibilityMenu = styled.div`
  display: flex;
  align-self: flex-start;
`;

const AccessibilityAuthenticationContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export { Tools };
