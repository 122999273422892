import React from "react";
import { ParagraphSpacingButton } from "./Buttons/ParagraphSpacingButton";
import { LinesSpacingButton } from "./Buttons/LinesSpacingButton";
import { WordSpacingButton } from "./Buttons/WordSpacingButton";
import { LettersSpacingButton } from "./Buttons/LettersSpacingButton";
import { NormalLettersButton } from "./Buttons/NormalLettersButton";
import { MediumLettersButton } from "./Buttons/MediumLettersButton";
import { BigLettersButton } from "./Buttons/BigLettersButton";
import { ContrastButton } from "./Buttons/ContrastButton";
import styled from "styled-components";

const AccessibilityMenu = () => {
  const accessibilityButtons = [
    <ParagraphSpacingButton />,
    <LinesSpacingButton />,
    <WordSpacingButton />,
    <LettersSpacingButton />,
    <NormalLettersButton />,
    <MediumLettersButton />,
    <BigLettersButton />,
    <ContrastButton />
  ];

  return (
    <StyledAccessibilityMenu>
      {accessibilityButtons.map(accessibilityButton => (
        <li>{accessibilityButton}</li>
      ))}
    </StyledAccessibilityMenu>
  );
};

const StyledAccessibilityMenu = styled.ul`
  display: flex;
  margin: 0 25px 0 0;
  list-style-type: none;
`;

export { AccessibilityMenu };
